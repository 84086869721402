@import "Colours";
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,200;0,400;1,100;1,300;1,800;1,900&display=swap');

.home{
    width: 100%;
    height: 100vh;
    background-color: $second;
    border-radius: 0 0 200px 200px;

    >main{
        position: absolute;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 2rem;
        justify-content: center;
        >h1{
            font:400 3rem "$text1";
            color: $first;
            font-family: sans-serif;
        }
        >p {
            font: 350 1rem "$text1";
            color: $first;
            font-family: sans-serif;
        }
    }
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background:no-repeat center/auto url("../Assets/background-2.jpg");
        border-radius: 0 0 100px 100px;
    }
}

.home2{
    width: 100%;
    height: 100vh;
    background-color: $second;
    padding: 400px 0;
    padding-right: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -200px;
    >img{
        width:25%;
        padding-left: 10%;
        animation: imgUp 0.9s linear infinite alternate;
    }
    >div{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;
        height: 100vh;
        >p{
            color: white;
            letter-spacing: 1px;
            word-spacing: 2px;
            font-family: sans-serif;
        };

    }

}
@keyframes imgUp{
    to{
        transform: translateY(-10px);
    }
}

.home3{
    width: 100%;
    height: 120vh;
    background-color: $second;
    margin-top: -200px;
    display: flex;
    >div{
        width: 70%;
        height: 100vh;
        background-color: $first;
        border-radius: 0 200px 200px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        >h1{
            text-transform: uppercase;
            border-bottom: 2px solid $dark;
            padding: 0.5rem;
            width:15rem;
            color: $dark;
        }
        >p{
            padding: 5rem;
            letter-spacing: 1px;
            word-spacing: 2px;
            font-family: sans-serif;
            line-height: 170%;
            font-style: italic;
        }
    }
}
.home4{
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: flex-end;
    >div{
        background-color: $third;
        border-radius: 200px 0 0 200px;
        width: 70%;
        height: 70vh;
        padding:2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        >h1{
            text-transform: uppercase;
            color: $first;
            width: 140px;
            border-bottom: 2px solid $first;
            padding: 0.5rem;
        }
        >article{
            display: flex;
            margin: 2rem 0;
            flex-wrap: wrap;
            justify-content: center;
            >div{
                animation:imgUp 0.7s linear infinite alternate;
                margin: 1rem;
                height: 10rem;
                width: 10rem;
                background-color: $first;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $dark;
                cursor: pointer;
                transition: all 0.5s;
                &:hover{
                    filter: invert(1);
                }
                svg{
                    font-size: 4rem;
                }
                p{
                    font: 900 1.2rem $text2;
                }
            }
        }

    }
}